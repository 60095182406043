<template>
	<div>
		<BlockUI :message="defaultMsg" :html="html" v-if="isLoading"></BlockUI>
		<CRow><CButton size="sm" class="btn btn-link back-btn" 
					@click="backToTable()"><i class="fa fa-arrow-left"></i>Back</CButton>
		</CRow>
		<CCard class="p-2"> 
			<CCardHeader style="padding: 0.50rem 1.25rem">
				<CRow>
					<CCol lg="4">
						<h5>Client Billing #{{dataParams.client_billing_no}}</h5>
					</CCol>
			     	<CCol lg="8">
			      		<div style="text-align: right">
			      			
	      					<CButton size="sm" v-if="config.getPermission('client billing').download && is_roadwise()" :disabled="dataParams.status && dataParams.status.toLowerCase() == 'billed'" color="info" @click="createInvoice">
								<i class="fa fa-plus"></i> {{ dataParams.xero_invoice_id ? 'Update Xero Invoice' : 'Create Xero Invoice' }}
							</CButton> 
							<CButton class="ml-2" size="sm" v-if="dataParams.xero_invoice_id && is_roadwise()" color="success" @click="exportInvoice">
								<i class="fa fa-download"></i> Download Xero Invoice
							</CButton>
							<CButton v-if="has_role(['company admin'])" class="ml-2" size="sm" color="info" variant="outline" @click="showHistoryModal">
								<i class="fa fa-list"></i> Record History
							</CButton> 
							&nbsp;
	      					<CButton size="sm" v-if="config.getPermission('client billing').download" color="info" variant="outline" @click="download">
								<i class="fa fa-download"></i> Export Billing</CButton> &nbsp;
			      			<!-- <CButton size="sm" color="danger" @click="backToTable()"><i class="fa fa-close"></i> Close</CButton> &nbsp; -->
			      		</div>
					</CCol>
				</CRow>
			</CCardHeader>
			<CCardBody>
					<CRow class="p-1">
						<CCol lg="12">
							<strong style="font-size:13px" class="text-dark">Billing Information</strong>
						</CCol>	
					</CRow>
					<CRow class="ml-3 mr-3 mt-0"> 
						<CCol lg="4">
							<div class="form-group">
							<small>Customer</small>
							<strong class="form-text mt-0">
								{{dataParams.customer ? dataParams.customer.customer_name : "N/A"}}
							</strong>
							</div>
						
						</CCol>
						<CCol lg="4">
							<div class="form-group">
							<small>Reference No.</small>
							<strong class="form-text mt-0">
								{{dataParams.ref_no ? dataParams.ref_no : "N/A"}}
							</strong>
							</div>
						</CCol>
						
						<CCol lg="2">
							<div class="form-group">
							<small>Tax Type</small>
							<strong class="form-text mt-0 text-uppercase">
								{{dataParams.tax_type ? (dataParams.tax_type == 'vat' ? 'VAT' : 'Non VAT') : "N/A"}}
							</strong>
							</div>
						</CCol>
						<CCol lg="2">
							<div class="form-group">
							<small>Status</small>
							<strong class="form-text mt-0 text-uppercase">
								{{dataParams.status ? dataParams.status : "N/A"}}
							</strong>
							</div>
						</CCol>
						<!-- <CCol lg="3">
							Customer: <strong>{{dataParams.customer ? dataParams.customer.customer_name : "N/A"}}</strong>
						</CCol> -->
						<!-- <CCol lg="3">
							Ref No.: <strong>{{dataParams.ref_no ? dataParams.ref_no : "N/A"}}</strong>
						</CCol>  -->
						<!-- <CCol lg="3" class="text-uppercase">
							Status: <strong>{{dataParams.status ? dataParams.status : "N/A"}}</strong>
						</CCol>  -->
						<!-- <CCol lg="3">
							Tax Type: <strong>{{dataParams.tax_type ? (dataParams.tax_type == 'vat' ? 'VAT' : 'Non VAT') : "N/A"}}</strong>
						</CCol>  -->
					</CRow> 
					
					<CRow class="ml-3 mr-3 mt-0" v-if="is_marvel()"> 
						<CCol lg="4">
							<div class="form-group">
							<small>Term</small>
							<strong class="form-text mt-0 text-capitalize" v-if="dataParams.term">
								{{dataParams.term != 'custom' ? dataParams.term+' Days':'Custom'}}
							</strong>
							<strong class="form-text mt-0 text-capitalize" v-else>
								N/A
							</strong>
							</div>
						</CCol>
						<CCol lg="4">
							<div class="form-group">
							<small>Due Date</small>
							<strong class="form-text mt-0">
								{{dataParams.term_due_date ? moment(dataParams.term_due_date).format('MMMM DD, YYYY'): 'N/A' }}
							</strong>
							</div>
						</CCol>
					</CRow> 
					<CRow class="p-1">
						<CCol lg="12">
							<strong style="font-size:13px" class="text-dark">Covered Trips</strong>
						</CCol>	
					</CRow>
					<CRow class="ml-3 mr-3 mt-0"> 
						<CCol lg="4">
							<div class="form-group">
							<small>Depot</small>
							<strong class="form-text mt-0 text-uppercase">
								{{dataParams.depot ? dataParams.depot.setting_name : "N/A"}}
							</strong>
							</div>
						</CCol>
						<CCol lg="4">
							<div class="form-group">
							<small>Origin</small>
							<strong class="form-text mt-0 text-uppercase">
								{{dataParams.origin ? dataParams.origin.setting_name : "N/A"}}
							</strong>
							</div>
						</CCol>
						<CCol lg="4">
							<div class="form-group">
							<small>Commodity</small>
							<strong class="form-text mt-0 text-uppercase">
								{{dataParams.commodity_type ? dataParams.commodity_type.setting_name : "N/A"}}
							</strong>
							</div>
						</CCol>
						

						<!-- <CCol lg="3">
							Depot: <strong>{{dataParams.depot ? dataParams.depot.setting_name : "N/A"}}</strong>
						</CCol>   -->
						<!-- <CCol lg="3">
							Origin: <strong>{{dataParams.origin ? dataParams.origin.setting_name : "N/A"}}</strong>
						</CCol>  -->
						<!-- <CCol lg="3">
							Commodity: <strong>{{dataParams.commodity_type ? dataParams.commodity_type.setting_name : "N/A"}}</strong>
						</CCol>  -->
					</CRow>  
					
					<CRow class="ml-3 mr-3" style="margin-top:-13px;"> 
						<CCol lg="4">
							<div class="form-group">
							<small>Delivery Date From</small>
							<strong class="form-text mt-0">
								{{dataParams.delivery_date_from ? moment.unix(dataParams.delivery_date_from).format('MMMM DD, YYYY') : "N/A"}}
							</strong>
							</div>
						</CCol>
						<CCol lg="4">
							<div class="form-group">
							<small>Delivery Date To</small>
							<strong class="form-text mt-0">
								{{dataParams.delivery_date_to ? moment.unix(dataParams.delivery_date_to).format('MMMM DD, YYYY') : "N/A"}}
							</strong>
							</div>
						</CCol>
						<!-- <CCol lg="3"> 
							Delivery Date From: <strong>{{dataParams.delivery_date_from ? moment.unix(dataParams.delivery_date_from).format('MMMM DD, YYYY') : "N/A"}}</strong>
						</CCol> 
						<CCol lg="3">
							Delivery Date To: <strong>{{dataParams.delivery_date_to ? moment.unix(dataParams.delivery_date_to).format('MMMM DD, YYYY') : "N/A"}}</strong>
						</CCol>  -->
					</CRow> 
					<br>
					<CRow class="mb-0"> 
						<CCol lg="12">
							<div class="table-responsive-sm table-responsive-md">
								<CDataTable
								:tableFilter='{ placeholder: "Search Booking #" }'
								:items="dataParams.booking"
								:fields="tableFields"
								hover
								striped
								small
								fixed
								pagination
								:itemsPerPage="20"
								
							>  
								
								</CDataTable> 
								<!-- <CPagination
									:active-page.sync="currentPage"
									:pages="Math.ceil(dataParams.booking.length / 20)"
									:activePage="currentPage"
									@update:activePage="updatePage"
								/> -->
							</div>
							
							<CRow >
								<CCol lg="8" class="text-right"></CCol>
								<CCol lg="4" class="text-left">
									<ul class="list-group list-group-flush">
										<li class="list-group-item d-flex justify-content-between align-items-center" 
											style="font-weight:700; font-size:20px">
											TOTAL 
											<strong >{{dataParams.total_amount | number_format('currency')}}</strong>
										</li>
									</ul>
								</CCol>
							</CRow>
							
							
						</CCol>  
					</CRow> 
						
				
			</CCardBody> 
		</CCard>
		<CModal title="History" :show.sync="show_history_modal" color="info" size="lg"> 
			<div class="pl-3 pr-3 pb-3"> 
				<CRow class="mb-2">
					<CCol lg="12">
						<CDataTable
							:items="history_log"
							:fields="[
								{
									key: 'event', 
									label: 'Action'
								},
								{
									key: 'date', 
									label: 'Date'
								},
								{
									key: 'display_name', 
									label: 'User'
								},
								{
									key: 'old_values',
									label : 'Changes Made'
								},
								
							]"
							hover
							striped
							small
							fixed
							custom
						>   
							<template #event="{item}">
								<td>
									<small>{{  item.event | upper }}</small>
								</td>
							</template>
							<template #date="{item}">
								<td>
									<small>{{  item.date }}</small>
								</td>
							</template>
							<template #display_name="{item}">
								<td>
									<small>{{  item.display_name }}</small>
								</td>
							</template>
							<template #old_values="{item}">
								<td v-html="changesTemplate(item.old_values,item.new_values, item.event)">
									
								</td>
							</template>
							
						</CDataTable> 
					</CCol> 
				</CRow> 
			</div>
			<div slot="footer" class="w-100">
				<CButton style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="show_history_modal = false">
					Close
				</CButton>  
			</div>
			<div slot="footer" class="w-100"></div>
		</CModal>
		<!-- <HistoryModal :details="dataParams" @showModal="showHistoryModal = $event" :showModal="showHistoryModal" /> -->
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import Loader from '../../../loader.mixin.js';
// import 'vue-select/dist/vue-select.css';
import '../../style.css';
import moment from 'moment';
// import HistoryModal from './history_modal/index';

export default {
	mounted(){  

		this.getData();
		

	},
	filters: {
		upper(val) {
			return (val) ? val.toUpperCase() : ''
		}
	},
	data(){
		return{
			company_type: JSON.parse(window.localStorage.getItem("user_data")).company.company_type.includes("Trucking") ? "trucking" : JSON.parse(window.localStorage.getItem("user_data")).company.company_type,
			displayedBooking: [],
			currentPage: 1,
      		isInvoiceGenerated: false,
			formModal: false,
			showViewModal: false,
			show_history_modal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "",
			dataParams: {
				customer_id: "",
				origin_id: "",
				commodity_id: "",
				ref_no: "",
				status: "",
				tax_type: "",
				delivery_date_from: "",
				delivery_date_to: "",
				total_amount: 0.00,
				overall_total:0.00,
				booking: [],
				xero_invoice_id : null
			},
			editMode: false, 
			vendorList: {
				data: []
			},
			vehicleTypeList: {
				data: []
			},
			makerModelList: {
				data: []
			},
			depotList: {
				data: []
			},
			originList: {
				data: []
			},
			isLoading: false,
			uploadPercentage: 0,
			config,
			moment,
			// use by monocrete
			fields:  [
				{
					key: 'booking_no', 
					label: 'Booking #'
					
				},
				// {
				// 	key: 'customer_id_label', 
				// 	label: 'Customer'
				// },
				// {
				// 	key: 'asset_type', 
				// 	label: 'Asset Type'
				// },
				{
					key: 'vehicle_type_id_label', 
					label: 'Trucker'
				}, 
				{
					key: 'delivery_date_label', 
					label: 'Delivery Date'
				}, 
				// {
				// 	key: 'booking_type_id_label', 
				// 	label: 'Type'
				// },
				{
					key: 'commodity_id_label', 
					label: 'Commodity'
				},
				// {
				// 	key: 'asset_no', 
				// 	label: 'Plate #'
				// }, 
				{
					key: 'depot_id_label', 
					label: 'Depot'
				}, 
				{
					key: 'origin_id_label', 
					label: 'Origin'
				}, 
				// {
				// 	key: 'destination_id_label', 
				// 	label: 'Destination'
				// }, 
				{
					key: 'no_of_trips', 
					label: 'No. of trips'
				}, 
				{
					key: 'client_rate', 
					label: 'Trip Rates'
				},
				{
					key: 'total_amount', 
					label: 'Total Rate'
				},
				{
					key: 'overall_total', 
					label: 'Total Amount'
				}
			],
			// use by roadwise
			fields_trucking:  [
				{
					key: 'booking_no', 
					label: 'Booking #'
					
				},
				// {
				// 	key: 'customer_id_label', 
				// 	label: 'Customer'
				// },
				// {
				// 	key: 'asset_type', 
				// 	label: 'Asset Type'
				// }, 
				{
					key: 'vehicle_type_id_label', 
					label: 'Trucker'
				}, 
				{
					key: 'delivery_date_label', 
					label: 'Delivery Date'
				}, 
				// {
				// 	key: 'booking_type_id_label', 
				// 	label: 'Type'
				// },
				{
					key: 'commodity_id_label', 
					label: 'Commodity'
				},
				// {
				// 	key: 'asset_no', 
				// 	label: 'Plate #'
				// }, 
				{
					key: 'depot_id_label', 
					label: 'Depot'
				}, 
				
				{
					key: 'origin_id_label', 
					label: 'Origin'
				}, 
				// {
				// 	key: 'destination_id_label', 
				// 	label: 'Destination'
				// }, 
				{
					key: 'total_amount', 
					label: 'Client Rate'
				},
				{
					key: 'toll_fees', 
					label: 'Toll Fees'
				}, 
				{
					key: 'extra_drop', 
					label: 'Extra Drop'
				}, 
				{
					key: 'extra_helper', 
					label: 'Extra Helper'
				}, 
				{
					key: 'other_fees', 
					label: 'Other Fees'
				}, 
				// {
				// 	key: 'no_of_trips', 
				// 	label: 'No. of trips'
				// }, 
				// {
				// 	key: 'client_rate', 
				// 	label: 'Trip Rates'
				// },
				
				{
					key: 'overall_total', 
					label: 'Total Amount'
				}
			],
			fields_marvel:  [
				{
					key: 'booking_no', 
					label: 'Booking #'
					
				},
				
				{
					key: 'vehicle_type_id_label', 
					label: 'Trucker'
				}, 
				{
					key: 'delivery_date_label', 
					label: 'Delivery Date'
				}, 
				
				{
					key: 'commodity_id_label', 
					label: 'Commodity'
				},
				
				{
					key: 'depot_id_label', 
					label: 'Depot'
				}, 
				
				{
					key: 'origin_id_label', 
					label: 'Origin'
				}, 
				
				{
					key: 'total_amount', 
					label: 'Client Rate'
				},
				{
					key: 'toll_fees', 
					label: 'Toll Fees'
				}, 
				
				{
					key: 'overall_total', 
					label: 'Total Amount'
				}
			],
			invoice_id : null,
			history_log: []

		}
	},
	computed: {
		changesTemplate() {
			return (value,value2, action) => {
				if(value && action == 'updated') {
					let html = '<small>';
					for (const key in value) {
						if (Object.hasOwnProperty.call(value, key)) {
							const element = value[key] ? value[key] : '(empty)';
							const element2 = value2[key] ? value2[key] : '(empty)';
							if(key == 'xero_invoice_generated')
								html+=`<span class="font-w800">Xero Invoice Generated</span><br>`;
							else if(key == 'xero_invoice_id')
								html+='';
							else
							if(element != element2)
								html+=`<span>${key} from <span class="text-danger font-w800">${element}</span> to <span class="text-danger font-w800">${element2}</span></span> <br>`
						}
					}
					html += '</small>';

					return html;
				}
				return ''
			}

			
		},
		tableFields() {
				const company_type = JSON.parse(window.localStorage.getItem("user_data"))?.company?.company_type ?? null;
				switch (company_type?.toLowerCase()) {
					case 'trucking with agency':
						return this.fields_trucking	;
					case 'trucking with no agency':
						return this.fields_marvel;			
					default:
						return this.fields
				}
				//company_type == 'trucking' ? fields_trucking : fields
		}
		
	},
	mixins: [Loader],
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
	methods: { 
		showHistoryModal() {
			this.$showLoading(true)
			let id = this.$route.params.id;
			
			axios.get(`${config.api_path}/client_billing/${id}/history`).then(res => {
				const {data, status} = res.data;
				this.history_log = data.map(l => {
					l.display_name = `${l.firstname} ${l.lastname}`;
					l.date = (l.updated_at) ? moment(l.updated_at).format('MM/DD/YYYY hh:mm A') : '';
					return l;
				});
				this.show_history_modal = !this.show_history_modal;
				this.$showLoading(false)
			}).catch(err => {
				console.error(err)
				this.$showLoading(false)
			})
			
		},
		exportInvoice() {
			this.$showLoading(true)
			axios.get(`${config.api_path}/xero/invoice-detail`,{
				params: {
					invoice_id : this.dataParams.xero_invoice_id
				}
				
			}).then(response => {
				try {
					this.$showLoading(false)
					window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

					setTimeout(() => {
						axios.get(`${config.api_path}/remove-file`,{
							params : {
								filename : response.data.file
							}
						})	
					}, 2000);
				} catch (error) {
					console.error(error)
				}
				
			}).catch(err => {
				this.$showLoading(false)
			})
		},
		
		
		getData(){
			this.$showLoading(true)
			axios.get(config.api_path+"/client_billing/"+this.$route.params.id)
			.then(response=>{
				this.$showLoading(false)
      		  	// console.log(response.data.data, 'SSSSSSSSSSSSS')
				const {data} = response.data;
				this.dataParams = {...data};
				
       			 this.isInvoiceGenerated = this.dataParams.xero_invoice_generated === 1
				this.dataParams.total_amount = 0.00 
				this.dataParams.booking = this.dataParams.booking.map((value, index)=>{
					value.toll_fees = value.billable?.toll_fee ? value.billable?.toll_fee : "N/A";
	    			value.extra_drop = value.billable?.extra_drop ? value.billable?.extra_drop : "N/A";
	    			value.extra_helper = value.billable?.extra_helper ? value.billable?.extra_helper : "N/A";
	    			value.other_fees = value.billable?.other_expenses ? value.billable?.other_expenses : "N/A";

	
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type?.setting_name
	    			}
	    			else{
	    				value.booking_type_id_label = "N/A"
	    			}

	    			if(value.customer_id){
	    				value.customer_id_label = value.customer?.customer_name
	    			}
	    			else{
	    				value.customer_id_label = "N/A"
	    			}

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin?.setting_name
	    			}
	    			else{
	    				value.origin_id_label = "N/A"
	    			}
					if(value.depot_id){
	    				value.depot_id_label = value.depot?.setting_name
	    			}
	    			else{
	    				value.origin_id_label = "N/A"
	    			}

	    			if(value.destination?.setting_name){
	    				value.destination_id_label = value.destination?.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			if(value.commodity_id){
	    				value.commodity_id_label = value.commodity?.setting_name
	    			}
	    			else{
	    				value.commodity_id_label = "N/A"
	    			} 

					if(value.asset_type == 'vehicle'){
	    				value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
	    			}
	    			if(value.asset_type == 'equipment'){
	    				value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
	    			}
	    			if(value.asset_type == 'furnishing'){
	    				value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
	    			}
	    			value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)
	    			

	    			if(value.delivery_date){
	    				value.delivery_date = value.delivery_date
	    			}
	    			else{
	    				value.delivery_date = "N/A"
	    			}
	    			if(value.client_ref_no == "" || value.client_ref_no == null){
	    				value.client_ref_no_label = "N/A";
	    			}
	    			if(value.maker_model_id){
	    				value.maker_model_id_label = value.maker_model?.setting_name
	    			}

	    			if(value.with_gps){
	    				value.with_gps_label = "Yes";
	    			}
	    			else{
	    				value.with_gps_label = "No";
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
	    			}
	    			else{
	    				value.delivery_date_label = "N/A"
	    			}

	    			if(value.total_amount){
	    				value.total_amount = value.total_amount;
	    			}
	    			else{
	    				value.total_amount = "0.00"
	    			}

	    			if(value.client_rate){
		    			value.client_rate= value.client_rate
		    		}
	    			
					else{
	    				value.client_rate = "0.00" 
	    			}
	    			if(value.is_subcon == false || value.is_subcon == 0){
    					value.vehicle_type_id_label = value.company.company_name
    				}
    				else{
    					if(value.vendor){
    						value.vehicle_type_id_label = value.vendor.vendor_name
    					}
    					else{
    						value.vehicle_type_id_label = "N/A"
    					}
    				}
					value.overall_total = 0;
	    			return value;
	    		}); 
				this.dataParams.total_amount = 0.00;
				// this.dataParams.clientrate_total = 0.00;
				// this.dataParams.sub_total = 0.00;
				// this.dataParams.extrahelper_total =0.00;
				// this.dataParams.otherfees_total =0.00;
				// this.dataParams.extradrop_total =0.00;
				// this.dataParams.tollfee_total =0.00;
				// this.dataParams.other_payments = 0.00;
				for(var i = 0; i < this.dataParams.booking.length; i++){
	    			this.dataParams.booking[i].toll_fees = this.dataParams.booking[i].billable?.toll_fee ? this.dataParams.booking[i].billable?.toll_fee : "N/A";
	    			this.dataParams.booking[i].extra_drop = this.dataParams.booking[i].billable?.extra_drop ? this.dataParams.booking[i].billable?.extra_drop : "N/A";
	    			this.dataParams.booking[i].extra_helper = this.dataParams.booking[i].billable?.extra_helper ? this.dataParams.booking[i].billable?.extra_helper : "N/A";
	    			this.dataParams.booking[i].other_fees = this.dataParams.booking[i].billable?.other_expenses ? this.dataParams.booking[i].billable?.other_expenses : "N/A";
	    			// g if nlng nko ni kay sa monocrete na side kay ang gna compute ra nila sa billing kay ang trips rates, tapos sa marvel lahi sad - april
					 if (this.is_roadwise()){
						if(this.dataParams.booking[i].total_amount != null){
							let extra_helper = this.dataParams.booking[i].billable?.extra_helper;
							if(!extra_helper){
								extra_helper = 0
							}
							let extra_drop = this.dataParams.booking[i].billable?.extra_drop;
							if(!extra_drop){
								extra_drop = 0
							}
							let toll_fee = this.dataParams.booking[i].billable?.toll_fee;
							if(!toll_fee){
								toll_fee = 0
							}
							let other_expenses = this.dataParams.booking[i].billable?.other_expenses;
							if(!other_expenses){
								other_expenses = 0
							}
							//NON VAT COMPUTATION
							this.dataParams.booking[i].overall_total = parseFloat((
									parseFloat(extra_helper) + 
									parseFloat(extra_drop) + 
									parseFloat(toll_fee) + 
									parseFloat(other_expenses) + 
									parseFloat(this.dataParams.booking[i].total_amount)
								));

							if(this.dataParams.tax_type === "vat"){
								let vat = (
									(
										parseFloat(this.dataParams.booking[i].total_amount) + 
										parseFloat(extra_drop) + 
										parseFloat(toll_fee) 
										// parseFloat(extra_helper) + 
										// parseFloat(other_expenses)
									) * 0.12
								).toFixed(2)
								this.dataParams.booking[i].overall_total += parseFloat(vat);
							}
							//computation in Total (client rate+extra drop +toll fee)
							// this.dataParams.sub_total += 
							// (
							// parseFloat(toll_fee) + 
							// parseFloat(extra_drop) + 
							// parseFloat(this.dataParams.booking[i].total_amount));
							
							//client rate total
							// this.dataParams.clientrate_total += 
							// (
							// parseFloat(this.dataParams.booking[i].total_amount));

							//toll fee total
							// this.dataParams.tollfee_total += 
							// (
							// parseFloat(toll_fee));

							//extra drop total
							// this.dataParams.extradrop_total += 
							// (
							// parseFloat(extra_drop));

							//extra helper total
							// this.dataParams.extrahelper_total += 
							// (
							// parseFloat(extra_helper));
							//other fees total
							// this.dataParams.otherfees_total += 
							// (
							// parseFloat(other_expenses));
							
							
							//Computation of other fees and extra_helper
							// this.dataParams.other_payments += 
							
							// (parseFloat(extra_helper) + 
							// parseFloat(other_expenses)); 
							

							//Over all tOtAL computation of client rate,tax, other expenses, extra drop , toll fee and client rate
							this.dataParams.total_amount += parseFloat(this.dataParams.booking[i].overall_total);
							

							//total amount in bookings list format
							this.dataParams.booking[i].overall_total = this.$options.filters.number_format(this.dataParams.booking[i].overall_total,'currency',2)
						
							
						} 

					}
					
					else if(this.is_marvel()){
						if(this.dataParams.booking[i].total_amount != null){
							let extra_helper = this.dataParams.booking[i].billable?.extra_helper;
							if(!extra_helper){
								extra_helper = 0
							}
							let extra_drop = this.dataParams.booking[i].billable?.extra_drop;
							if(!extra_drop){
								extra_drop = 0
							}
							let toll_fee = this.dataParams.booking[i].billable?.toll_fee;
							if(!toll_fee){
								toll_fee = 0
							}
							let other_expenses = this.dataParams.booking[i].billable?.other_expenses;
							if(!other_expenses){
								other_expenses = 0
							}
							//NON VAT COMPUTATION
							this.dataParams.booking[i].overall_total = parseFloat((
									parseFloat(toll_fee) + 
									parseFloat(this.dataParams.booking[i].total_amount)
								));

							if(this.dataParams.tax_type === "vat"){
								let vat = (
									(
										parseFloat(this.dataParams.booking[i].total_amount) + 
										parseFloat(toll_fee) 
									) * 0.12
								).toFixed(2)
								this.dataParams.booking[i].overall_total += parseFloat(vat);
							}
							

							//Over all tOtAL computation of client rate,tax, other expenses, extra drop , toll fee and client rate
							this.dataParams.total_amount += parseFloat(this.dataParams.booking[i].overall_total);
							

							//total amount in bookings list format
							this.dataParams.booking[i].overall_total = this.$options.filters.number_format(this.dataParams.booking[i].overall_total,'currency',2)
						
							
						} 

					}
					
					else {
						if(this.dataParams.booking[i].total_amount != null){
							let extra_helper = this.dataParams.booking[i].billable?.extra_helper;
							if(!extra_helper){
								extra_helper = 0
							}
							let extra_drop = this.dataParams.booking[i].billable?.extra_drop;
							if(!extra_drop){
								extra_drop = 0
							}
							let toll_fee = this.dataParams.booking[i].billable?.toll_fee;
							if(!toll_fee){
								toll_fee = 0
							}
							let other_expenses = this.dataParams.booking[i].billable?.other_expenses;
							if(!other_expenses){
								other_expenses = 0
							}
							//NON VAT COMPUTATION
							this.dataParams.booking[i].overall_total = parseFloat((
				
									parseFloat(this.dataParams.booking[i].total_amount)
								));

							if(this.dataParams.tax_type === "vat"){
								let vat = (
									(
										parseFloat(this.dataParams.booking[i].total_amount) 
										
									) * 0.12
								).toFixed(2)
								this.dataParams.booking[i].overall_total += parseFloat(vat);
							}
				
							//Over all tOtAL computation of client rate,tax, other expenses, extra drop , toll fee and client rate
							this.dataParams.total_amount += parseFloat(this.dataParams.booking[i].overall_total);
							

							//total amount in bookings list format
							this.dataParams.booking[i].overall_total = this.$options.filters.number_format(this.dataParams.booking[i].overall_total,'currency',2)
						
							
						} 
					}
						
	    		}
				// this.dataParams.total_amount = response.data.data.total_amount
	    		this.displayedBooking = [...this.dataParams.booking];  
	    		this.displayedBooking.splice(((this.currentPage-1) + 20), this.dataParams.booking.length)
			})
			.catch(err => {
			this.$showLoading(false)
		}) 

		}, 

	 
	    updateCompanyOwned(e){
	    	this.dataParams.company_owned = e;
	    },


	    backToTable(){
	    	this.$router.push('/main/client_billing')
	    },

	    download(){ 
	    	this.$showLoading(true)
	    	axios.get(config.api_path+'/reports/client_billing/'+this.$route.params.id)
	    	.then(response=>{
				this.$showLoading(false)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
	    		setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
	    	})
			.catch(err => {
				this.$showLoading(false)
			})
	    },

	    createInvoice(){
			Swal.fire({
				title: this.dataParams.ref_no,
				// text: "Please confirm this reference no. and make sure the billing data is correct. ",
				html: 'Please confirm this <b> reference no. </b> and make sure the billing data is correct.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Submit',
				reverseButtons: true,
			
			}).then((result) => {
				if (result.isConfirmed) {
					this.invoiceMethod()
				}
			})
	    },
		invoiceMethod() {
			this.$showLoading(true);
			
			let user_id = config.global_user_data.id;
			let depot_id = config.global_user_data.depot_id;

			const xero_apis = axios.create();
			xero_apis.post(config.api_path+'/xero/invoice/create', {
				client_billing_id: this.$route.params.id,
				invoice_id : this.dataParams.xero_invoice_id,
				user_id,
				depot_id
				})
					.then(response =>{
						this.$showLoading(false)
						const {status, message, field, result} = response.data;
						if(status == 'error') {
							let footer = null;
							let btnText = 'Connect to Xero';
							switch(field) {
								case 'customer':
									btnText = null;
									break;
								case 'auth':
									btnText = 'Connect to Xero';
									break;
							}

							Swal.fire({
								icon:'warning',
								title: '',
								html: message, 
								showCancelButton: true,
								confirmButtonText: btnText, 
								showConfirmButton : btnText ? true : false,
								reverseButtons: false,

							}).then((result) => { 
								if (result.isConfirmed) {

									this.connectToXero();
								}
							})  
							
						}
						else if(result?.length) {
							const res = result[0];
							if(res.HasErrors) {
								Swal.fire({
									icon: 'warning',
									title: '',
									html: res.ValidationErrors[0]?.Message,
								})
							}
							else {
								// Swal.fire({
								// 	icon: 'success',
								// 	title : this.dataParams.xero_invoice_id ? 'Invoice Updated.' : 'Invoice generated.',
								// 	showConfirmButton: false,
								// 	timer : 2000
								// })

								Swal.fire({
									icon:'success',
									title : this.dataParams.xero_invoice_id ? 'Invoice Updated.' : 'Invoice generated.',
									html: `You can view the invoice created by clicking 'Generate Xero Invoice' button.`, 
									showCancelButton: true,
									confirmButtonText: 'Download Xero Invoice', 
									showConfirmButton : true,
									confirmButtonColor : 'success',
									reverseButtons: false,

								}).then((result) => { 
									if (result.isConfirmed) {

										this.exportInvoice()
									}
								})  
								this.isInvoiceGenerated = true;
								this.dataParams.status = 'Billed';
								this.dataParams.xero_invoice_id = res.InvoiceID;
							}
						}
						
					})
				.catch(err => {
					Swal.fire({
						icon: 'warning',
						title: 'Error!',
						// text: 'Xero account configuration error!',
						html: 'Xero account configuration error! <small><br> Note: Please make sure that the invoice status is <b> DRAFT </b> before updating. </small>',
						// footer: `<pre>${JSON.stringify(err)}</pre>`
					})
				this.$showLoading(false)



        })
		},
		connectToXero() {
			let user_id = config.global_user_data.id;
			axios.get(`${config.main_path}/manage/xero/${user_id}`).then(response => {
				let responseHtml = response.data;
				console.log(responseHtml, "Monitoring");
				//open the new window and write your HTML to it
				var myWindow = window.open("", "_blank", "resizable=yes");
				myWindow.document.write(responseHtml);
			})
		}


 	}
}
</script>
